import { useWindowSize } from '@react-hook/window-size'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import Masonry from 'react-masonry-component'
import { Post } from './post'

// constants

const ALL_POSTS = graphql`
  query {
    allSanityPost(filter: { visible: { eq: true } }, sort: { sequence: ASC }) {
      edges {
        node {
          _rawSummary
          _rawThumbnailImage
          slug {
            current
          }
          title
          titleInBody
        }
      }
    }
  }
`

const PATHNAME_REGEX = /^\/(bryn\/)?$/

// hooks

const useHooks = () => {
  const masonryRef = useRef()

  const { allSanityPost } = useStaticQuery(ALL_POSTS)

  const [posts, setPosts] = useState(allSanityPost.edges)

  return { masonryRef, posts, setPosts }
}

// components

export const Posts = ({ pathname }) => {
  const { masonryRef, posts, setPosts } = useHooks()

  useEffect(() => {
    const post = posts.findIndex(edge => edge.node.slug.current === pathname)

    if (post !== -1 && post !== posts.length - 1) {
      setPosts(posts.filter((_, index) => index !== post).concat(posts[post]))
    }

    setMounted('posts posts--mounted')
  }, [pathname, posts, setPosts])

  const [mounted, setMounted] = useState('posts')

  const size = useWindowSize()

  useEffect(() => masonryRef.current.masonry.layout(), [masonryRef, size])

  const updatedPosts = pathname.match(PATHNAME_REGEX) ? posts : posts.slice(0, -1)

  return (
    <Masonry className={mounted} ref={masonryRef}>
      {updatedPosts.map(({ node: post }) => (
        <Post classes="post" key={post.title} link={true} post={post} />
      ))}
    </Masonry>
  )
}
