import { Link } from 'gatsby'
import React from 'react'

// local components

const ExternalLink = ({ children, mark }) =>
  mark.blank ? (
    <a href={mark.url} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <a href={mark.url}>{children}</a>
  )

const InternalLink = ({ children, mark }) => <Link to={mark.slug}>{children}</Link>

const Mailto = ({ children, mark }) => <a href={`mailto:${mark.email || 'yokaisoho@gmail.com'}`}>{children}</a>

const Span = ({ children }) => <span>{children}</span>

// exported objects

export const serializers = {
  marks: {
    externalLink: ExternalLink,
    internalLink: InternalLink,
    mailto: Mailto,
    span: Span
  }
}

// exported functions

export const nIntervals = (callback, delay, n) => {
  let count = 0

  const intervalId = window.setInterval(() => {
    callback()

    if (++count === n) {
      window.clearInterval(intervalId)
    }
  }, delay)
}
