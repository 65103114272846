module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.14.0__react-dom@18.3.1_j2i6nrbj3uqbcazvzqdyo5ezoq/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.14.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__mbibknafthoztq2rmynwnkuysq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
