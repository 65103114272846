import React, { createContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Company, Logo } from '../components/layout'
import { Posts } from '../components/posts'

// local constants

const KEYWORDS = 'London, UK'

// exported constants

export const DATASET = 'live'

export const HOME = '/'

export const PROJECT_ID = '51ewiyc3'

export const PROPERTY = 'iffy studio'

// exported objects

export const Context = createContext({})

// exported components

export const Head = ({ canonical = '/', title = KEYWORDS }) => (
  <Helmet>
    <link href={`https://iffy.studio${canonical}`} rel="canonical" />

    <title>{`${PROPERTY} | ${title}`}</title>
  </Helmet>
)

export const Layout = ({ children, location: { pathname } }) => {
  useEffect(() => setHeaderClass(), [])

  const [headerClass, setHeaderClass] = useState('header--initial')

  const [postPosition, setPostPosition] = useState()

  return (
    <Context.Provider value={{ postPosition, setPostPosition }}>
      <div className="all all--home">
        <header className={headerClass}>
          <Logo path={pathname} />

          <Company path={pathname} />
        </header>

        {children}

        <Posts pathname={pathname} />
      </div>
    </Context.Provider>
  )
}

export default Layout
